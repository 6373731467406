/* tslint:disable */
/* eslint-disable */
export enum UserSurveyStatusGenerated {
  TO_BE_SENT = 'toBeSent',
  TO_BE_FILLED = 'toBeFilled',
  FILL_IN_PROGRESS = 'fillInProgress',
  FILLED_TO_BE_SENT = 'filledToBeSent',
  FILLED_SENT = 'filledSent',
  EXPIRED = 'expired'
}
