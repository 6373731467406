import { Injectable } from '@angular/core';
import { BusinessUnitGenerated } from '@generated/client/models';
import { THEMES } from '@models/themes';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private _currentTheme = new BehaviorSubject<THEMES>(THEMES.DEFAULT);
  theme$: Observable<string> = this._currentTheme.asObservable();

  setTheme(theme: THEMES): void {
    this._currentTheme.next(theme);
  }

  static getThemeFromBusinessUnit(bu?: BusinessUnitGenerated): THEMES {
    switch (bu) {
      case BusinessUnitGenerated.FR:
        return THEMES.FR;
      case BusinessUnitGenerated.FIMM:
        return THEMES.FIMM;

      default:
        return THEMES.DEFAULT;
    }
  }
}
