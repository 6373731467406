/* tslint:disable */
/* eslint-disable */
export enum SurveyTypeGenerated {
  WELCOME_CALL = 'welcomeCall',
  FIRST_CALL = 'firstCall',
  SECOND_CALL = 'secondCall',
  THIRD_CALL = 'thirdCall',
  QUALITY = 'quality',
  QUALITY_60 = 'quality60'
}
